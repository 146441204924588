import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_BASE_URL + '/api';
axios.defaults.withCredentials = true;

const ApiService = {

    get(resource, data) {
        return axios.get(resource, data)
    },

    getBaseUrl() {
        return axios.defaults.baseURL.replace('/api', '');
    },
}

export default ApiService
